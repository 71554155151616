<script setup lang="ts">
import ClientOnly from '@/components/utils/ClientOnly.vue';
import WelcomeHeroAnimation from './WelcomeHeroAnimation.vue';

</script>

<template>
  <ClientOnly>
    <WelcomeHeroAnimation />
  </ClientOnly>
</template>