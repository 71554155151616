<script setup lang="ts">
import rangeTransform from "@/lib/rangeTransform";
import { useElementHover, usePreferredReducedMotion } from "@vueuse/core";
import { ref, watch } from "vue";

// TODO: fix jagged pause / play, ignored for now as its assumed to be unlikely that pause/play will be happening for 99% of users.

type LastActionActions = "buy-the-dip" | "sell-the-high";

interface LastActionBase<T extends LastActionActions> {
  action: Extract<LastActionActions, T>;
  at: number; // history id
  buyPrice: number;
  amount: number;
}

interface LastActionSell extends LastActionBase<"sell-the-high"> {
  sellPrice: number;
}

interface LastActionBuy extends LastActionBase<"buy-the-dip"> {}

type LastAction = LastActionSell | LastActionBuy;

interface Config {
  coin: Uppercase<string>;
  portfolio: number; // how many coins do you have?
  history: {
    size: number;
    visibleInUi: number;
  }; // keep these values the same, no calculations are done involving out-of-ui elements. premature optimization. still.
  chart: {
    priceRange: {
      high: number;
      low: number;
    }; // values in fiat
    uiHeight: number; // height of the encapsulating DOM element in PX ( will be converted to rem at 1:16 ratio automatically. ), changing this makes the Y axis scale of the candlesticks longer / shorter, other than that has no effect.
    redZone: number; // ex. 10/100 <= 10% of config.chart.priceRange's difference, defines red zones for the top and bottom of the chart, max movement is the equal to red zone. Buy/sell operations take place at the bottom and top red zones.
    markers: {
      gapBetween: number;
      height: number;
    };
  };
  charting: {
    minMovement: number; // ex. 1/100 <= 1%, minimum movement for each step
    biasBoostInterval: number; // how many steps until the next bias gets triggered, biases managed under class Candlestick.getBiasedDirection
    initialLastAction: LastAction;
    candlestick: {
      candleWidth: number;
      stickWidth: number;
      spacing: number;
    };
  };
  animation: {
    // IMPORTANT: Careful while modifying these, as most transition timings are statically set in tailwind and might collide with css transitions.
    stepDuration: number; // how much time should a steps duration take? ms.
    stepDelay: number; // how much time should a steps delay take? ms.
    showLastActionFor?: number | undefined; // how many steps should last action be shown for? - leave undefined to wait till next notification
    fps: number;
  };
}

const config: Config = {
  coin: "ETH",
  portfolio: 30,
  history: {
    size: 40,
    visibleInUi: 40,
  },
  chart: {
    priceRange: {
      high: 2853.23,
      low: 1236.9,
    },
    uiHeight: 800,
    redZone: 10 / 100,
    markers: {
      gapBetween: 60,
      height: 18,
    },
  },
  charting: {
    minMovement: 0.5 / 100,
    biasBoostInterval: 10,
    initialLastAction: {
      action: "sell-the-high",
      at: 0,
      sellPrice: 3200,
      buyPrice: 900,
      amount: 2,
    },
    candlestick: {
      candleWidth: 20,
      stickWidth: 4,
      spacing: 10,
    },
  },
  animation: {
    stepDuration: 300,
    stepDelay: 200,
    showLastActionFor: undefined,
    fps: 60,
  },
};

// start a11y related references
const preferredMotion = usePreferredReducedMotion();
const notificationContainerElRef = ref<HTMLDivElement>();
const notificationContainerIsHovered = useElementHover(
  notificationContainerElRef
);
// end a11y releated references

let isLoopRunning = false;
const canvasElRef = ref<HTMLCanvasElement>();
const canvasWrapperElRef = ref<HTMLDivElement>();

const range = config.chart.priceRange.high - config.chart.priceRange.low;

const redZones = {
  top: config.chart.priceRange.high - range * config.chart.redZone,
  bottom: config.chart.priceRange.low + range * config.chart.redZone,
};

let count = 0; // step count & used as id for candlesticks

const lastAction = ref<LastAction>(config.charting.initialLastAction);

const notification = ref<LastAction | null>(null);

// set new lastActions as new notifications
watch(lastAction, (action) => {
  notification.value = action;
});

class Candlestick {
  stickHigh: number;
  stickLow: number;

  open: number;
  close: number;

  id: number;

  candleHigh: number;
  candleLow: number;

  direction: "down" | "up";

  height: number;
  topStickHeight: number;
  bottomStickHeight: number;

  ui: {
    marginTop: number;
    canvasYOffset: number;
    candleHeight: number;
    topStickHeight: number;
    bottomStickHeight: number;
  };

  constructor() {
    this.open =
      history.value[history.value.length - 1]?.close ??
      range / 2 + config.chart.priceRange.low; // use last close from history if it exists, otherwise the mid point of the range
    this.close = this.open + this.getMovement(this.open);

    const isOpenHigher = this.open > this.close;

    if (isOpenHigher) {
      this.candleHigh = this.open;
      this.height = this.open - this.close;
      this.direction = "down";
      this.candleLow = this.close;
    } else {
      this.candleHigh = this.close;
      this.height = this.close - this.open;
      this.direction = "up";
      this.candleLow = this.open;
    }

    this.stickHigh =
      this.candleHigh +
      this.easeInCubic(Number(Math.random().toFixed(2))) *
        range *
        (config.chart.redZone - config.charting.minMovement) +
      range * config.charting.minMovement;
    this.stickLow =
      this.candleLow -
      (this.easeInCubic(Number(Math.random().toFixed(2))) *
        range *
        (config.chart.redZone - config.charting.minMovement) +
        range * config.charting.minMovement);

    this.topStickHeight = this.stickHigh - this.candleHigh;
    this.bottomStickHeight = this.candleLow - this.stickLow;

    const uiCandleHeight = transformHeightToCanvasValue(this.height);

    const test =
      (config.chart.uiHeight / 2 -
        transformToCanvasValue(this.candleHigh) +
        (this.direction === "down" ? uiCandleHeight : 0)) *
      -1;

    this.ui = {
      marginTop:
        config.chart.uiHeight - transformToCanvasValue(this.candleHigh),
      candleHeight: uiCandleHeight,
      canvasYOffset: test,
      topStickHeight: transformHeightToCanvasValue(this.topStickHeight),
      bottomStickHeight: transformHeightToCanvasValue(this.bottomStickHeight),
    };

    this.id = ++count;

    this.evaluatePosition();
  }

  easeInCubic(x: number): number {
    return x * x * x;
  }

  getMovement(open: number) {
    const movement =
      Number(Math.random().toFixed(2)) *
        range *
        (config.chart.redZone - config.charting.minMovement) +
      range * config.charting.minMovement;

    let direction = +1;

    if (open < redZones.bottom) direction = +1;
    else if (open > redZones.top) direction = -1;
    else direction = this.getBiasedDirection();

    return movement * direction;
  }

  evaluatePosition() {
    if (
      this.open < redZones.bottom &&
      lastAction.value.action !== "buy-the-dip"
    ) {
      lastAction.value = {
        at: this.id,
        action: "buy-the-dip",
        buyPrice: this.close,
        amount: Number(
          (
            ((config.portfolio * 95) / 100) * Number(Math.random().toFixed(2)) +
            (config.portfolio * 5) / 100
          ).toFixed(2)
        ),
      };
    } else if (
      this.open > redZones.top &&
      lastAction.value.action !== "sell-the-high"
    ) {
      lastAction.value = {
        at: this.id,
        action: "sell-the-high",
        buyPrice: lastAction.value?.buyPrice ?? 0,
        sellPrice: this.close,
        amount: lastAction.value?.amount ?? 1,
      };
    }
  }

  getBiasedDirection() {
    const randomVal = Number(Math.random().toFixed(2));

    /**
     * bias pushes the chart towards the direction it should be going to more and more dpending on the boost value, the direction
     * is inferred based on the previous action, the only thing that should be edited in this function is the boost values.
     *
     * boost values are between 0, and .5. 0 meaning no bias, and .5 meaning every step ( while the boost is .5 ) shall 100% be towards
     * the target direction. (your boost value + .5 is the total chance of the next step going in the target direction)
     */

    let boost = 0.1;

    const difference = count - lastAction.value.at;

    if (difference > config.charting.biasBoostInterval * 3) boost = 0.5;
    else if (difference > config.charting.biasBoostInterval * 2) boost = 0.3;
    else if (difference > config.charting.biasBoostInterval) boost = 0.2;

    if (
      (lastAction.value.action === "sell-the-high" &&
        randomVal < 0.5 - boost) ||
      (lastAction.value.action === "buy-the-dip" && randomVal >= 0.5 - boost)
    )
      return +1;

    return -1;
  }
}

const history = ref<Candlestick[]>([]);
addInitialCandleSticks();

let lastShift = performance.now();
const stepInterval = config.animation.stepDuration + config.animation.stepDelay;

safeStartLoop();

watch(
  notificationContainerIsHovered,
  (isHovered) => {
    if (preferredMotion.value === "reduce") return;

    if (isHovered) safeStopLoop();
    else safeStartLoop();
  },
  { immediate: true }
);

watch(
  preferredMotion,
  (motion) => {
    if (motion === "no-preference") safeStartLoop();
    else if (motion === "reduce") safeStopLoop();
  },
  { immediate: true }
);

function safeStartLoop() {
  // avoids loop() being called twice. That would run the animation twice as fast with inconsistent intervals.

  if (isLoopRunning) return;
  else {
    isLoopRunning = true;
    loop();
  }
}

function safeStopLoop() {
  isLoopRunning = false;
}

async function loop() {
  const now = performance.now();

  // based on isLoopRunning state, stop execution, but if stopped, do a final render.
  // if(!isLoopRunning) return renderToCanvas(now)

  if (isLoopRunning) {
    setTimeout(
      async () => requestAnimationFrame(loop),
      config.animation.fps / 1000
    );

    if (now > lastShift + stepInterval) {
      lastShift = now;
      shiftCandleSticks();
      removeTimedOutNotifications();
    }
  }

  renderToCanvas(now);
}

function removeTimedOutNotifications() {
  // remove notification if timed out
  if (
    config.animation?.showLastActionFor &&
    notification.value &&
    notification.value.at + config.animation.showLastActionFor < count
  )
    notification.value = null;
}

function addInitialCandleSticks() {
  [...new Array(config.history.size)].forEach(() => {
    const candlestick = new Candlestick();
    history.value.push(candlestick);
  });
}

function shiftCandleSticks() {
  const candlestick = new Candlestick();

  if (history.value.length >= config.history.size) history.value.shift();
  history.value.push(candlestick);
}

function transformToCanvasValue(input: number) {
  return rangeTransform(
    [config.chart.priceRange.low, config.chart.priceRange.high],
    [0, config.chart.uiHeight],
    input
  );
}

function transformHeightToCanvasValue(input: number) {
  return rangeTransform([0, range], [0, config.chart.uiHeight], input);
}

// https://stackoverflow.com/questions/2901102/how-to-format-a-number-with-commas-as-thousands-separators
function numberWithCommas(x: number | string): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function renderToCanvas(now: number) {
  const canvasWrapperEl = canvasWrapperElRef.value;
  const canvasEl = canvasElRef.value;
  if (!canvasEl || !canvasWrapperEl) return;

  const ctx = canvasEl.getContext("2d");
  if (!ctx) return;

  const wrapperBoundingRect = canvasWrapperEl.getBoundingClientRect();
  const height = (canvasEl.height = wrapperBoundingRect.height);
  const width = (canvasEl.width = wrapperBoundingRect.width);

  const items = history.value;

  const transitionProgress = Math.min(
    (now - lastShift) / config.animation.stepDuration,
    1
  );

  const transitionOffsetRight =
    transitionProgress *
    (config.charting.candlestick.candleWidth +
      config.charting.candlestick.spacing);

  // clear canvas
  ctx.clearRect(0, 0, width, height);

  // iterate through history, from last to first
  for (let i = items.length - 1; i >= 0; i--) {
    const candlestick = items[i];
    if (!candlestick) return;

    // if "new item" then set alpha
    if (i === items.length - 1) {
      ctx.globalAlpha = transitionProgress;
    }

    const indexFromRight = items.length - i;
    const candleMarginLeft =
      width -
      transitionOffsetRight -
      (indexFromRight * config.charting.candlestick.candleWidth +
        (indexFromRight - 1) * config.charting.candlestick.spacing);
    const stickMarginLeft =
      candleMarginLeft +
      (config.charting.candlestick.candleWidth / 2 -
        config.charting.candlestick.stickWidth / 2);

    ctx.beginPath();

    // draw candle
    ctx.rect(
      candleMarginLeft,
      candlestick.ui.marginTop,
      config.charting.candlestick.candleWidth,
      candlestick.ui.candleHeight
    );

    // draw stick top
    ctx.rect(
      stickMarginLeft,
      candlestick.ui.marginTop - candlestick.ui.topStickHeight,
      config.charting.candlestick.stickWidth,
      candlestick.ui.topStickHeight
    );

    // draw stick bottom
    ctx.rect(
      stickMarginLeft,
      candlestick.ui.marginTop + candlestick.ui.candleHeight,
      config.charting.candlestick.stickWidth,
      candlestick.ui.bottomStickHeight
    );

    if (candlestick.direction === "up") {
      const gradient = ctx.createLinearGradient(0, 0, 0, 20);
      gradient.addColorStop(0, "#69FFEA");
      gradient.addColorStop(1, "#69FFFF");

      ctx.fillStyle = gradient;
    } else if (candlestick.direction === "down") {
      ctx.fillStyle = "rgba(105, 255, 234, 10%)";
    }

    ctx.fill();

    ctx.closePath();

    // reset alpha for further renders
    ctx.globalAlpha = 1;
  }
}
</script>

<template>
  <Transition
    appear
    appear-active-class="duration-6000 lt-md:duration-600 ease-out"
    appear-from-class="opacity-0"
  >
    <div
      class="w-full h-full flex items-center justify-center isolate relative"
      role="presentation"
      aria-label=""
    >
      <div class="w-full h-full flex items-center justify-center relative">
        <!-- chart animation -->
        <div
          class="flex justify-end absolute -translate-x-[calc(50%-0.5625rem)] transform-gpu"
        >
          <!-- for testing: Center point for animation viewport -->
          <!-- <div
            class="absolute left-full top-1/2 h-.5 w-20 bg-gradient-to-b from-red to-green"
          >
          </div> -->
          <div
            :style="{
              height: (config.chart.uiHeight / 16).toFixed(2) + 'rem',
              transform: `translateY(${((history[history.length - 1]?.ui.canvasYOffset ?? 0) / 16).toFixed(2)}rem)`,
              transitionDuration: config.animation.stepDuration + 'ms',
            }"
            class="w-max flex gap-2 relative ease-in-out transform-gpu"
          >
            <!-- markers -->
            <div
              class="absolute right-0 top-1/2 -translate-y-1/2 translate-x-[200%] h-[200%] flex flex-col gap-4 opacity-50"
              :style="{
                gap: (config.chart.markers.gapBetween / 16).toFixed(2) + 'rem',
              }"
            >
              <div
                v-for="(, index) in [...new Array(Math.floor(config.chart.uiHeight*2 / (config.chart.markers.gapBetween + config.chart.markers.height) /* 2x uiHeight / (gap + height) */))]"
                :key="index"
                :style="{
                  height: (config.chart.markers.height / 16).toFixed(2) + 'rem',
                }"
                class="w-24 rounded-full bg-brand-stroke/10"
              />
            </div>

            <!-- center line -->
            <div
              class="w-.5 h-[200%] bg-brand-stroke/5 absolute right-2.25 top-1/2 -translate-y-1/2"
            ></div>

            <!-- for testing: full height indic -->
            <!-- <div
                class="absolute left-full top-0 h-full w-20 bg-gradient-to-b from-red to-green"
              >
              </div> -->

            <!-- the 7.5 is a magical number, don't ask. I don't know. -->
            <div ref="canvasWrapperElRef" class="h-full w-full translate-x-7.5">
              <canvas ref="canvasElRef" class="h-full w-full"> </canvas>
            </div>
          </div>
        </div>

        <!-- action pulse -->
        <!-- <div class="w-.5 h-.5 bg-brand-gradient absolute animate-pulse animate-duration-300 lg:hidden" /> -->

        <!-- notifications -->
        <div
          class="absolute lg:(translate-x-[calc(50%+1rem)]) lt-lg:(top-.5 scale-90 origin-t) lt-xs:(left-0)"
        >
          <Transition
            enter-active-class="duration-500 ease-out"
            leave-active-class="duration-100 ease-in"
            enter-from-class="translate-y-4 opacity-0"
            leave-to-class="-translate-y-4 opacity-0"
            mode="out-in"
          >
            <div v-if="notification" :key="notification.at">
              <div
                class="rounded-8 flex items-center gap-4 px-4 py-2 bg-brand-background/80 backdrop-blur-sm border-2 border-brand-stroke/10 w-100 lt-xs:w-88"
              >
                <div
                  class="flex-shrink-0 h-10 w-10 relative flex items-center justify-center before:(content-empty absolute inset-0 bg-brand-gradient opacity-10 rounded-full)"
                >
                  <span
                    :class="[
                      'h-6 w-6 inline-block bg-brand-gradient',
                      notification.action === 'buy-the-dip' &&
                        'i-material-symbols:sync animate-spin animate-reverse animate-duration-1400',
                      notification.action === 'sell-the-high' &&
                        'i-material-symbols:check',
                    ]"
                  />
                </div>

                <div ref="notificationContainerElRef" >
                  <span
                    class="font-semibold text-xs text-white/20 inline-block leading-none"
                  >
                    {{ $t('operation') }}:
                    <template v-if="notification.action === 'buy-the-dip'">
                      {{$t('buy_the_dip')}}
                    </template>
                    <template
                      v-else-if="notification.action === 'sell-the-high'"
                    >
                      {{ $t('sell_the_high') }}
                    </template>
                  </span>
                  <span
                    class="text-white/40 inline-block leading-tight font-medium"
                  >
                    <template v-if="notification.action === 'buy-the-dip'">
                      {{ $t('bought') }}
                      <span class="text-brand-gradient"
                        >{{ notification.amount }} {{ config.coin }}</span
                      >, {{ $t('watching_market') }}.
                    </template>
                    <template
                      v-else-if="notification.action === 'sell-the-high'"
                    >
                      {{ $t('sell_order_for') }}
                      <span class="text-brand-gradient"
                        >{{ notification.amount }} {{ config.coin }}</span
                      >
                      {{$t('fulfilled')}}.
                      <span class="text-brand-primary/80">{{ $t('profit_made') }}: </span>
                      <span class="text-brand-gradient">
                        {{
                          numberWithCommas(
                            (
                              (notification.sellPrice - notification.buyPrice) *
                              notification.amount
                            ).toFixed(2)
                          )
                        }}
                        USDT </span
                      >.
                    </template>
                  </span>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </Transition>
</template>
